<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

// import DropZone from "@/components/widgets/dropZone";
// import datepicker from "vue3-datepicker";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Create New Project",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    // DropZone,
    // datepicker,
    Layout,
    PageHeader,
    loaderProcess,
  },
  data() {
    return {
      title: "Modifier une Categorie",
      process: false,
      modalOk: false,
      titre:"",
      progress:0,
      categ: {
        titre: "",
        description: "",
        image: "",
      },
      categories: [],
      items: [
        {
          text: "Catégorie",
          href: "/projects/categories",
        },
        {
          text: "Modifier",
          active: true,
        },
      ],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   maxFilesize: 0.5,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // daterange: ref(new Date()),
    };
  },
  methods: {
    changeImage() {
      this.$refs.imgCateg.click()
    },
    imageCategorie(event) {
      this.imgCat = event.target.files[0]
      this.categ.image = this.imgCat.name
    },
    updateChannel() {

      this.process = true

      Api.putFormData("/streamvod/rest/programme/update-programme/" + this.$route.params.id, {
        name: this.programme.titre,
        description: this.programme.description,
        duree: this.programme.duree
      })
        .then( () => {
          this.process = false
          this.$router.push("/projects/programmes");
        }).catch((error) => {
          this.process = false
          Erreur.gestionErreur(error.message)
        })
    },

  },
  mounted() {
    this.titre = "Récupération des contenus"
    this.process = true
    Api.get('/streamvod/rest/categories/' + this.$route.params.id)
      .then((response) => {
        this.process = false
        this.categ.titre = response.data.content.name
        this.categ.description = response.data.content.description
        this.categ.image = response.data.content.images.name
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="padding: 25px; width: 65%; margin-left: auto; margin-right: auto; border-radius: 15px;">

          <div class="card-body">
            <h4 class="card-title mb-3">{{ $t('pages.updateCateg.formulaire.title') }}</h4>
            <form>
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label col-lg-2">{{ $t('pages.updateCateg.formulaire.titre.label')
                }}</label>
                <div class="col-lg-10">
                  <input id="projectname" name="projectname" type="text" class="form-control" v-model="categ.titre"
                    :placeholder="$t('pages.updateCateg.formulaire.titre.placeholder')" />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectbudget" class="col-form-label col-lg-2">{{ $t('pages.updateCateg.formulaire.image')
                }}</label>
                <div class="col-lg-10">
                  <input id="projectbudget" name="projectbudget" type="file" @change="imageCategorie($event)"
                    class="form-control" accept=".png, .jpeg, .jpg" ref="imgCateg" style="display: none;" />
                  <div style="display: flex;">

                    <p>{{ categ.image }}</p>
                    <div @click="changeImage" class="btn c2play-primary" style="margin-left: auto;">Modifier</div>

                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="projectdesc" class="col-form-label col-lg-2">{{
                  $t('pages.updateCateg.formulaire.description.label') }}</label>
                <div class="col-lg-10">
                  <textarea id="projectdesc" class="form-control" rows="3" v-model="categ.description"
                    :placeholder="$t('pages.updateCateg.formulaire.description.placeholder')"></textarea>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-lg-10" style="margin-top: 10px; align-items: center; justify-content: center;">
                <button type="submit" class="btn c2play-primary" style="position: relative; left: 50%;"
                  @click="updateProgramme">
                  {{ $t("updateButton") }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <loader-process :visible="process" :progress="progress" :title ="titre"/>
    <!-- end row -->
  </Layout>
</template>

<style>
.select-action {
  display: flex;
  justify-content: space-between;
}

.select-action p {
  text-align: center;
  width: 100%;
  font-size: 1.3em;
  cursor: pointer;
  transition: all 0.14s ease;
}

.select-action p:hover {
  transform: scale(1.080);
}

.borderB {
  border-bottom: 2px solid #556ee6;
}
</style>